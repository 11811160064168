.sender {
  text-align: right;
  color: green;
}

.receiver {
  text-align: left;
  color: red;
}

.messageBox {
  width: 500px;
  margin: auto;
  height: 150px;
  overflow: auto;
}

.roomlist {
  cursor: pointer;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.messenger {
  display: grid;
  width: 100%;
  height: calc(100vh - 63px);
  background: #eeeef1;
  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  margin-top: -15px;
}
.messenger .toolbar {
  height: 50px;
  background: #f1f1f1;
  width: 100%;
}
.messenger .compose .toolbar {
  background: transparent;
  height: auto;
  width: auto;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}

.conversation-list {
  display: flex;
  flex-direction: column;
}
.conversation-list-group {
  overflow-y: scroll;
  height: calc(100vh - 180px);
}
.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e7e7e7;
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conversation-search {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.conversation-search-input {
  background: #f4f4f8;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}

.conversation-search-input::placeholder {
  text-align: center;
}

.conversation-search-input:focus::placeholder {
  text-align: left;
}

.toolbar-button {
  color: #007aff;
  font-size: 28px;
  transition: all 0.1s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #0063ce;
}

.toolbar-button:active {
  color: #007aff;
  opacity: 0.25;
}

.toolbar {
  display: flex;
  align-items: center;

  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;

  position: sticky;
  top: 0px;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.toolbar-title {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  width: 100%;
}
.msg-header {
  display: flex;
  justify-content: start;
  width: 100%;
}
span.toggle-btn {
  cursor: pointer;
}
.toggle-menu-btn {
  width: fit-content;
  cursor: pointer;
}
.left-items,
.right-items {
  flex: 1;
  padding: 10px;
  display: flex;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}

.message-list-container {
  padding: 10px;
  padding-bottom: 50px;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 183px);
}

.compose {
  padding: 10px;
  display: flex;
  width: 1000px !important;
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: fixed;
  width: calc(100% - 20px);
  bottom: 0px;
}

form {
  display: contents;
}

@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.compose-input {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}

.message {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  word-break: break-all;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.conversation-info {
  text-align: left;
  width: calc(100% - 50px);
}
.message-list .toolbar {
  background: #f9f9f9;
}

.message-form-top .compose {
  border-top: 1px solid #f5f5f5;
  position: relative;
  width: 100% !important;
  padding: 10px 20px;
  align-self: flex-end;
  height: 70px;
  display: flex;
}
.messenger .compose .toolbar .right-items {
  padding: 0;
}
.message-form-top .compose .btn.btn-primary {
  padding: 8px 15px !important;
  font-size: 15px;
}

.message-list-container::-webkit-scrollbar,
.conversation-list-group::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.message-list-container::-webkit-scrollbar-track,
.conversation-list-group::-webkit-scrollbar-track {
  background-color: #fff;
}
.message-list-container::-webkit-scrollbar-track:hover,
.conversation-list-group::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.message-list-container::-webkit-scrollbar-thumb,
.conversation-list-group::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
.message-list-container::-webkit-scrollbar-thumb:hover,
.conversation-list-group::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.message-list-container::-webkit-scrollbar-button,
.conversation-list-group::-webkit-scrollbar-button {
  display: none;
}

/* Handle */
.message-list {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  ul {
    margin: 0 auto;
  }
}

.user-online {
  font-size: 30px;
  color: green;
}

.user-offline {
  font-size: 30px;
}

.unread-message-count {
  background-color: #1f3893;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  padding: 1px 2px;
  margin-left: 5px;
}

// .lst-message{
//   text-align: left !important;
// }

// .roomlist-time{
//   text-align: right !important;
// }
