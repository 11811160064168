.install-inner {
  display: block;
  font-weight: 700;
  margin: 4% 0%;
}

.images-modal {
  display: flex;
  justify-content: space-around;
  border: 1px solid #d3d3d3;
  padding: 5px;
}
.search-fields {
  display: flex;
  justify-content: space-around;
}
.form-group {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.sidenav---navicon---3gCRo {
  font-size: 2em;
  color: #fff;
}
.sidenav---navtext---1AE_f {
  font-size: initial;
  font-weight: bold;
}
.sidenav---navtext---1AE_f {
  color: white;
}
.sidenav---sidenav---_2tBP {
  background: #1f3893 !important;
}
.sidenav---navicon---3gCRo i {
  color: #fff !important;
}
.sidenav---navtext---1AE_f {
  color: #fff !important;
}
span.shopname-nav {
  line-height: 64px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
}
.header-in-all {
  position: sticky;
  top: 0;
  background: transparent;
  z-index: 1099;
  padding: 0 15px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
}
.header-in-all,
span.shopname-nav {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.header-logo {
  color: #f6ab36;
}
.container-header .icon-logo {
  margin-right: 5px;
}
.container-header {
  padding: 15px 0;
  z-index: 1099;
  background: #fff;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  opacity: 0.8;
  border-bottom: 1px solid #e8e8e8;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo {
  opacity: 1 !important;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > * {
  color: #f6ab36 !important;
  fill: #f6ab36 !important;
}
.root-page {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.main-container {
  position: relative;
  width: calc(100% - 64px);
  max-width: 100%;
  display: block;
  /* transition: width 0.05s; */
  background: #ffff;
  min-height: 250px;
}
.sidenav---expanded---1KdUL + .main-container {
  width: calc(100% - 240px);
  /* transition: width 0.05s; */
}
.page-container {
  padding: 15px 20px;
}
.flex-jb {
  display: flex;
  justify-content: space-between;
}
.flex-inline-jb {
  display: inline-flex;
  justify-content: space-between;
}
button.margin-left-15.btn.btn {
  margin-left: 15px;
}
.col-8 {
  border-left: solid gray;
  text-align: left;
}
.col-4 {
  text-align: left;
}
.text-bold {
  font-weight: 700;
}
.card-body {
  box-shadow: 5px 10px 18px #888888;
}
.mar-15 {
  margin: 12px 0px 0px 0px;
}
.rotateimg90 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
form.wapp-btn {
  line-height: 100px;
  background: #f6f6f6;
  padding: 5px;
}
.header-logo:hover {
  cursor: pointer;
  color: darkslategrey;
}
ul.innerClass {
  margin-top: 0;
  margin-bottom: 1rem;
  display: flex;
  width: auto;
  border: 0;
  justify-content: center;
}
.extra-pro-images .modal-body .images-modal div img {
  object-fit: cover;
  object-position: center;
}
.link-name {
  color: #0077cc;
  text-decoration: underline;
}
.link-name:hover {
  color: #0095ff;
  cursor: pointer;
}

.sidenav---sidenav---_2tBP {
  position: fixed;
}
.search-fields {
  margin: 20px 0px;
  justify-content: space-between;
}
.search-fields .input-group {
  width: 32%;
  margin-top: 25px;
}
.page-container {
  padding: 0 20px;
  position: relative;
}
.sidenav---sidenav---_2tBP {
  transition: none;
}
.search-fields .input-group svg {
  position: absolute;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  fill: rgba(0, 0, 0, 0.4);
}
.t-product-img-container {
  width: 100px;
  height: 100px;
  display: flex;
  max-width: 100%;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  border-radius: 10px;
  border: 2px solid #d1d1d1;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}
.document-view {
  margin-top: -25px;
  margin-left: -41px;
  font-size: 18px;
  font-weight: 900;
}
.t-product-img-container:hover {
  cursor: pointer;
}
.profit-cost-inner div {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 10px auto;
}
.pro-img-viewmore-btn {
  width: auto;
  background: #1f3893;
  color: #fff;
  text-decoration: none;
  border-radius: 100px;
  padding: 3px 10px;
  margin: 5px auto 0;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  max-width: 92px;
}
.t-product-title {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  width: 100%;
  text-transform: none;
  margin-top: 5px;
}
.t-product-analytics div {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin: 00;
  justify-content: space-between;
  padding: 3px 0;
}
.pro-data-table.table td,
.pro-data-table.table th {
  vertical-align: middle;
  padding: 10px !important;
  border-color: #f1f1f1;
}
.pro-rating-group span {
  font-size: 20px !important;
  line-height: 20px;
}
.t-product-analytics div.pro-rating-group div {
  align-items: center;
  margin: 0 0 0 0;
  font-size: 14px;
  padding: 0;
}
.t-product-analytics div.pro-rating-group {
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 0;
}
.search-fields .input-group .container {
  padding: 0;
  border: 1px solid #ced4da;
}
span.css-bgvzuu-indicatorSeparator.select_pro_category__indicator-separator {
  display: none;
}
.search-fields .input-group .container .css-1rtrksz {
  background: #fff;
  padding: 0px 8px;
}
.pro-rating-value {
  margin-left: auto !important;
}
.p-engage-data span {
  vertical-align: middle;
  line-height: 25px;
}
.pro-small-txt {
  font-size: 14px;
  min-width: 120px;
  word-break: break-word;
  max-height: 160px;
  padding: 4px;
  overflow: auto;
}
.pro-small-txt button,
.y-video-container button {
  background: transparent;
  border: 0;
  color: #007bff;
  text-decoration: underline;
  font-weight: 700;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pro-small-txt button:hover,
.y-video-container button:hover {
  background: transparent;
  color: #0062cc;
  text-decoration: underline;
}
.t-pro-links {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.t-pro-links .t-link-icon:nth-child(3n) {
  margin-right: 0;
}
.sidenav---sidenav-navitem---uwIJ-:first-child {
  display: none;
}
.t-product-analytics {
  width: 100%;
}
.pro-data-table button {
  font-size: 14px;
  border-radius: 0;
  min-width: 100px;
}
.fb-ads-img::after {
  position: absolute;
  content: "Click to view";
  width: 100%;
  left: 0;
  font-size: 12px;
  bottom: 0;
}
.fb-ads-img {
  position: relative;
  cursor: pointer;
  display: block;
  padding-bottom: 20px;
}
.modal-header .close {
  padding: 0;
  margin: 0;
  font-size: 35px;
  position: absolute;
  right: 10px;
  top: 3px;
  z-index: 99999;
  outline: none !important;
}
li.itemClass {
  margin: 0;
  display: flex;
}
li.itemClass a.linkClass {
  color: #222;
  padding: 5px 20px;
  border: 1px solid #e8e8e8;
  font-size: 18px;
  background: #f5f5f5;
  text-decoration: none;
}
li.itemClass.disabled a.linkClass {
  opacity: 0.4;
  cursor: not-allowed;
}
.search-fields .input-group input {
  box-shadow: none;
  border-radius: 0;
}
li.itemClass.activeClass a.linkClass.activeLinkClass {
  background: #e8e8e8;
}
span.css-d8oujb {
  display: none;
}
.select_pro_category {
  box-shadow: none;
  border: 1px solid #ced4da;
}
.search-fields .input-group .form-control:focus,
.form-control:focus {
  border-color: #2684ff;
}
.select_pro_category__control {
  border: 0 !important;
  border-radius: 0 !important;
  border-color: #000 !important;
}
.extra-content-modal .modal-body .images-modal div {
  width: 113px;
  height: 113px;
  display: flex;
  margin-right: 15px;
  border: 2px solid #d1d1d1;
  margin-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.2);
}
.carousel .thumbs-wrapper {
  display: none !important;
}
.extra-content-modal .modal-header .modal-title {
  font-size: 16px;
  color: #222;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding-right: 20px;
}
.extra-content-modal .modal-body .images-modal {
  border: 0;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.extra-content-modal .modal-body .images-modal div:nth-child(6n) {
  margin-right: 0;
}
iframe.extra-video-group {
  width: 100%;
  height: 100%;
}
.extra-video-modal .images-modal {
  justify-content: flex-start;
  border: 0;
  padding: 0;
  flex-wrap: wrap;
}
.extra-video-modal .images-modal .e-video-box {
  width: 32%;
  margin-right: 15px;
  margin-bottom: 15px;
}
.extra-video-modal .images-modal .e-video-box:nth-child(3n) {
  margin-right: 0;
}
.e-video-box .link-name {
  text-align: center;
  margin-top: -5px;
}
.extra-pro-images div.modal-title,
.extra-video-modal div.modal-title {
  font-size: 18px !important;
  color: #222;
  font-weight: 700;
  padding-right: 20px;
  letter-spacing: 0.5px;
}
.modal {
  z-index: 10999 !important;
}
.extra-pro-images .modal-body {
  display: flex;
  justify-content: space-between;
}
.extra-pro-images .modal-body .e-pro-main-img {
  width: 32%;
  display: flex;
  border: 2px solid #d1d1d1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.25);
}
.extra-pro-images .modal-body .images-modal {
  width: 65%;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: 0;
  padding: 0;
}
.extra-pro-images .modal-body .images-modal div {
  width: 113px;
  height: 113px;
  border: 2px solid #d1d1d1;
  margin-right: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.25);
}
.extra-pro-images .modal-body .images-modal div:nth-child(4n) {
  margin-right: 0;
}
.grid.product-details {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.product-images-container {
  width: 35%;
}
.product-details-container {
  width: 60%;
}
.product-images-container .product-image {
  height: 385px;
  border: 2px solid #d1d1d1;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}
.product-images-container .product-image img {
  height: auto;
  margin: 0;
  max-width: 100%;
  cursor: pointer;
}
.product-images-container ul.product-image-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.product-images-container ul.product-image-list li.image-item {
  cursor: pointer;
  border-right: 2px solid #d1d1d1;
}
.image-list .image-item {
  border: 2px solid #d1d1d1;
  border-radius: 10px;
  overflow: hidden;
}
.product-gallery .carousel.carousel-slider {
  border-radius: 10px;
  overflow: hidden;
  max-height: 150px;
  border: 2px solid #d1d1d1;
}
.product-image-list .carousel.carousel-slider {
  min-width: 408px;
  min-height: 150px;
}
.shopify-modal-container .product-image-list .carousel.carousel-slider {
  min-width: auto;
  min-height: auto;
}
.s-pro-block-content .image-list-icon div,
.s-pro-block-content .image-list div:last-child {
  margin: 0 !important;
}
.product-details-container h2.pro-title {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
}
h4.pro-sub-title {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #555;
  letter-spacing: 0.5px;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #e8e8e8;
}
.second-nav .column-xs-12 {
  padding: 0 0 5px !important;
}
.breadcrumb-list .breadcrumb-item,
.breadcrumb-list a {
  font-size: 16px;
  color: #222;
  text-transform: none;
  padding: 0;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.pro-description {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.products-related-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.products-info-box .card-header {
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 16px;
  background: #1f3893;
}
.products-info-box {
  width: 32%;
}
.products-info-box .card-body {
  box-shadow: 5px 10px 18px rgba(0, 0, 0, 0.2);
  padding: 10px;
}
.products-info-box .card-body li {
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
}
.products-info-box .card-body li span {
  font-size: 14px;
  color: #888;
}
.products-info-box .card-body li b {
  font-size: 14px;
  color: #555;
  letter-spacing: 0.5px;
}
.products-info-box .card-body li.li-product-rating {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.products-info-box .link-card {
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.products-info-box .card-body li.li-product-rating span {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.products-info-box .card-body li.li-product-rating span.s-rating-counter {
  display: block;
  text-align: right;
}
.products-info-box .card-body li.li-product-rating .rating-flex span {
  width: auto;
  font-size: 24px !important;
}
.products-info-box .card-body li.li-product-rating .rating-flex div {
  display: flex;
  width: 100%;
}
li.breadcrumb-item:hover {
  cursor: pointer;
  text-decoration: underline;
}

/*--- support form ---*/

.app-support-form-inner {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  border-bottom: 6px solid #e8e8e8;
}
.app-support-form-inner .form-group {
  flex-wrap: wrap;
}
.app-support-form-inner .form-group label {
  font-size: 14px;
  color: #555;
}
.app-support-form-inner .form-group input {
  border-radius: 0;
  height: 50px;
  box-shadow: none;
}
.app-support-form-inner .form-group textarea {
  border-radius: 0;
  box-shadow: none;
}
.section-title {
  font-size: 30px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
  text-align: left;
  max-width: 90%;
  margin: 0 auto 30px;
}
.app-support-btn-group {
  display: flex;
  margin-top: 10px;
}
.section-title::after {
  position: absolute;
  width: 60px;
  height: 3px;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  content: " ";
  background: #222;
}
.alert-dismissible.show {
  position: fixed;
  width: 460px;
  top: 60px;
  z-index: 1099;
  max-width: 100%;
  right: 0;
  padding: 4px;
  border-radius: 0;
}
.alert-dismissible .close {
  padding: 2px 10px;
}
.alert-dismissible p {
  margin-bottom: 5px;
}
.app-support-btn-group button {
  min-width: 150px;
  margin-right: 15px;
  min-height: 42px;
  border-radius: 0;
}
.add-to-shopify-content-modal .modal-header {
  border-bottom: none;
  padding: 0;
}
.add-to-shopify-content-modal
  .modal-body
  .shopify-modal-container
  .grid.product-details {
  margin-top: 0;
  padding: 0px 0 0 0;
}
.base-price {
  margin: 0 !important;
  display: block !important;
  width: 100%;
  text-align: right;
  font-size: 11px !important;
  color: #808080;
}

/*---- help css ----*/

.accordion .card-header {
  background: transparent;
  border: 0;
  box-shadow: none;
  padding: 0;
}
.accordion .card {
  border: 0;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;
}
.accordion {
  width: 90%;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  border-bottom: 6px solid #e8e8e8;
}
.accordion .card-header button {
  width: 100%;
  background: #f9f9f9;
  color: #222;
  text-align: left;
  box-shadow: none;
  border: 1px solid #f0f0f0;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 0;
}
.accordion .card-body {
  border: 1px solid #f1f1f1;
  text-align: left;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0 !important;
}
.t-pro-info-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.t-pro-info-containe img {
  max-width: 100%;
  height: auto;
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.y-video-container iframe {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid #d1d1d1;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.25);
}
.y-video-container .pro-img-viewmore-btn {
  max-width: 100%;
  margin-top: 10px;
}
.main-container-home .product-details-container {
  width: 60%;
}
.main-container-home .product-image-list .carousel.carousel-slider {
  min-width: 305px;
  min-height: 150px;
}
img.logo-vd {
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 4px solid #d3e1f5;
  padding: 5px;
  max-width: 160px;
  min-width: 160px;
  min-height: 160px;
}
.y-video-container .link-name {
  background: #f6ab36;
  border-radius: 100px;
  text-decoration: none;
  font-size: 12px;
  color: #000;
  padding: 3px 10px;
  max-width: 140px;
  margin: 5px auto;
}
.pro-small-txt .pro-img-viewmore-btn {
  max-width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn.btn-primary,
.signup-btn .btn-secondary {
  background: #1f3893;
  border-color: #1f3893;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
button.ats-single.btn.btn-primary {
  background: #f6ab36;
  border-color: #f5a11e;
  border-radius: 100px;
  padding: 5px 10px;
  color: #fff;
  font-weight: 700;
  margin-right: 15px;
}
.pro-data-table.table th {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #000;
  font-weight: 700;
  background: #f9f9f9;
}
.pro-small-txt .t-product-img-container {
  margin-left: auto;
  margin-right: auto;
  width: 80px;
  height: 80px;
}
.s-pro-block-content .s-block-discription {
  text-align: left;
  margin-bottom: 20px;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #5e5e5e;
}
.s-pro-block-content .image-list div img {
  width: auto;
  max-width: 100%;
  height: auto;
}
.s-pro-block-content .image-list-icon div img {
  width: auto;
  max-width: 100%;
  height: auto;
}
.s-pro-block-content .image-list div {
  width: 100px;
  height: 100px;
  display: flex;
  margin-right: 20px;
  margin-bottom: 20px;
}
.s-pro-block-content .image-list-icon div {
  width: 100px;
  height: 100px;
  display: flex;
  margin-right: 20px;
  margin-bottom: 20px;
}
.s-pro-block-content .image-list {
  flex-wrap: wrap;
}
.s-pro-block-content .image-list-icon {
  flex-wrap: wrap;
  margin: 0px 0px 8px 0px;
}
.fb-ads-img img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto 5px;
  border-radius: 10px;
  border: 2px solid #d1d1d1;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.2);
}
.e-video-box .link-name {
  background: #dea244;
  color: #000;
  font-size: 14px;
  text-decoration: none;
  padding: 3px 10px;
  border-radius: 50px;
  width: 200px;
  margin: 0 auto;
}
.t-block-discription {
  max-height: 100px;
  overflow: hidden;
  overflow-y: auto;
}
.t-pro-links .t-link-icon {
  width: 28%;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  position: relative;
  min-height: 48px;
}
.link-card .t-link-icon {
  width: 31%;
  margin-right: 5px;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
}
.link-card .t-link-icon:nth-child(3n) {
  margin-right: 0;
}
.link-card .t-link-icon {
  height: 52px;
  margin-right: 7px;
  margin-bottom: 7px;
}
.s-pro-title {
  font-size: 24px;
  color: #222;
  text-align: left;
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
  margin-top: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.youtube-videos .link-name {
  background: #1f3893;
  border-radius: 100px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  padding: 5px;
}
.products-video-row .youtube-videos iframe {
  width: 250px;
  height: 150px;
}
.youtube-videos div.s-video-container {
  margin-right: auto;
  margin-bottom: 0;
}
.youtube-videos div.s-video-container:nth-child(4n) {
  margin-right: 0;
}
button.btn.btn-danger {
  border-radius: 100px;
  padding: 5px 10px;
  border-color: #dc3545;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.t-product-img-container-youtube {
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto 5px;
}
.t-product-img-container-youtube:hover {
  cursor: pointer;
  opacity: 0.8;
}
.table-action-buttons {
  margin-bottom: 5px;
}
button.find-influ.mt-0.mr-3 {
  border-color: #f5a11e;
  border-radius: 100px;
  padding: 5px 10px;
  margin-top: 5px;
  color: #fff;
  opacity: 0.7;
  background: #f6ab36;
}
.ats-bp {
  display: block;
  width: 100%;
  text-align: right;
  font-size: 12px;
}
.ats-sign {
  vertical-align: middle;
  height: 45px;
}
button.btn.btn-primary.add-to-shopify-btn {
  background: #f6ab36;
  border-color: #f5a11e;
  border-radius: 100px;
  padding: 6px 0px;
  margin-top: 5px;
  min-width: 100px;
}
.pro-description .rdw-editor-toolbar {
  display: none !important;
}
.rdw-editor-toolbar {
  margin-bottom: 0px;
}
.editorClassName.rdw-editor-main {
  max-height: 170px;
  overflow: auto;
}
.container-home-single-products {
  padding: 60px 125px;
}
.editorClassName.rdw-editor-main {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: #fff;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.styles_Editext__button__6H8n_ {
  padding: 0.1em !important;
}
.rdw-option-disabled {
  opacity: 0.3;
  cursor: default;
  display: none;
}
.rdw-history-wrapper,
.rdw-remove-wrapper,
.rdw-link-wrapper,
.rdw-image-wrapper,
.rdw-embedded-wrapper {
  display: none !important;
}
.carousel .slide {
  background: #fff;
}
.carousel.carousel-slider .control-arrow,
.carousel .control-arrow {
  background: #000;
  opacity: 0.2 !important;
}
button.control-arrow.control-prev.control-disabled:hover {
  opacity: 0.1;
}
.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0) !important;
  opacity: 0.3 !important;
}
.shopify-modal-container h4.pro-sub-title {
  text-align: left;
  margin-top: 11px;
  margin-bottom: 8px;
  color: #555;
  letter-spacing: 0.5px;
  font-size: 18px;
  padding-bottom: 2px;
  border-bottom: 2px solid #e8e8e8;
}

.carousel .control-dots .dot {
  opacity: 0.7;
}

.styles_Editext__input__1534X {
  width: 100%;
  border: 1px solid rgb(212, 212, 212);
  padding: 4.8px;
  outline: none;
  height: 30px;
  margin-left: 2px;
}
.ats-icon {
  min-width: 25px;
  min-height: 25px;
}
.ats-icon svg {
  overflow: hidden;
  height: 25px;
  width: 25px;
}
.shopify-modal-container .carousel.carousel-slider {
  flex-flow: nowrap;
  max-height: 150px;
  border: 2px solid #d1d1d1;
  border-radius: 10px;
  overflow: hidden;
}
li.slide.selected,
.shopify-fields input.form-control {
  max-width: 100px;
}
.product-image-list .image-ats {
  border-right: 2px solid #d1d1d1;
  cursor: pointer;
}
.shopify-fields {
  display: flex;
  flex-wrap: nowrap;
}
.shopify-fields label.form-label {
  margin-right: 10px;
}
.shopify-fields input.form-control {
  padding: 0.3rem !important;
}
.shopify-field-p-title .shopify-fields-holder {
  width: 100%;
}
.shopify-field-p-title input.form-control {
  padding: 0.3rem !important;
  height: 30px !important;
  margin-left: 2px;
}
.styles_Editext__input__1534X {
  padding: 0.4em;
  border-radius: 5%;
  line-height: 1.5;
}
.y-video-container {
  position: relative;
  padding-bottom: 20px;
}
.y-video-container::after {
  position: absolute;
  content: "Click to view";
  width: 100%;
  left: 0;
  bottom: 0;
  font-size: 12px;
  cursor: pointer;
}
.product-youtube-video-modal iframe {
  height: 350px !important;
  width: 765px !important;
}

.t-link-icon {
  cursor: pointer;
}

.modal-body img {
  cursor: pointer;
}

.s-pro-block-content .image-list-icon div {
  width: 60px;
  height: 80px;
  position: relative;
  padding-bottom: 15px;
}
.s-pro-block-content .image-list-icon div::after {
  position: absolute;
  content: "Click to View";
  left: 0;
  bottom: -3px;
  font-size: 12px;
  color: #000;
  white-space: nowrap;
}
.select_pro_category__control {
  min-height: 35px !important;
}
.modal-body .shopify-modal-container .grid.product-details {
  flex-wrap: inherit;
  padding: 5px;
}
.modal-body .shopify-modal-container .product-images-container {
  width: 100%;
  max-width: 320px;
  margin-right: 15px;
}
.modal-body .shopify-modal-container .product-details-container {
  width: 100%;
  max-width: 385px;
  margin-top: 20px !important;
}
.shopify-modal-container .public-DraftStyleDefault-block {
  margin: 0.2em 0;
}
.ats-filds-container .shopify-fields .form-group {
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  position: relative;
}
.modal-backdrop {
  z-index: 1099 !important;
}
.ats-filds-container .shopify-fields .form-group .validation-msg {
  position: absolute;
  bottom: -10px;
  width: 100%;
  left: 0;
  font-size: 12px;
}
.ats-filds-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ats-filds-container .shopify-fields .form-group label.form-label {
  margin-bottom: 0;
}
.modal-body
  .shopify-modal-container
  .product-details-container
  .editorClassName.rdw-editor-main {
  margin-bottom: 1rem;
}
.carousel .control-disabled.control-arrow {
  display: block;
  cursor: not-allowed;
}

.css-1rtrksz.select_pro_category__value-container:hover {
  cursor: text;
}
.sort-by-product:hover {
  cursor: pointer;
  opacity: 0.7;
}
.fb-ads-img img:hover {
  opacity: 0.9;
}
.back-btn {
  background: #f6ab36;
  border-color: #f5a11e;
  border-radius: 100px;
  padding: 0px 16px;
  color: #fff;
  font-size: 12px;
  vertical-align: middle;
  line-height: 28px;
  font-weight: 700;
  margin-right: 15px;
}
.back-btn:hover {
  cursor: pointer;
  opacity: 0.9;
}
.footer-sp {
  display: flex;
  justify-content: flex-start;
}
.footer-sp button.add-to-shopify-btn.btn.btn-primary {
  margin-top: 0px !important;
  font-weight: 700;
  color: #fff;
  font-size: 13px;
}
.public-DraftStyleDefault-ltr {
  max-height: 150px !important;
}
.ats-title .styles_Editext__view_container__2l2kB {
  font-size: 20px;
  font-weight: 700;
}
.shopify-fields input.form-control {
  padding: 0.3rem !important;
  height: 30px !important;
  margin-left: 2px;
}
/*page not found*/

body.colorlib-body {
  padding: 0;
  margin: 0;
}

.colorlib-body #colorlib-notfound {
  height: 100vh;
}

#colorlib-notfound {
  position: relative;
  height: 100vh;
  background: linear-gradient(120deg, #e0eafc 75%, rgba(222, 162, 68, 0.8) 0);
}
#colorlib-notfound .colorlib-notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.colorlib-notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}

.colorlib-notfound .colorlib-notfound-404 {
  height: 158px;
  line-height: 153px;
}

.colorlib-notfound .colorlib-notfound-404 h1 {
  font-family: "Josefin Sans", sans-serif;
  color: #222;
  font-size: 220px;
  letter-spacing: 10px;
  margin: 0px;
  font-weight: 700;
  text-shadow: 2px 2px 0px #c9c9c9, -2px -2px 0px #c9c9c9;
  line-height: 0.5;
}

.colorlib-notfound .colorlib-notfound-404 h1:after,
.colorlib-notfound .colorlib-notfound-404 h1:before {
  display: none;
}

.colorlib-notfound .colorlib-notfound-404 h1 > span {
  text-shadow: 2px 2px 0px #ffab00, -2px -2px 0px #ffab00, 0px 0px 8px #ff8700;
}

.colorlib-notfound p {
  font-family: Josefin Sans, sans-serif;
  color: #1f3893;
  font-size: 30px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 15px;
}
.search-fields .input-group .css-10nd86i {
  border: 1px solid rgb(206, 212, 218);
  width: 100%;
}
.colorlib-notfound button {
  font-family: Josefin Sans, sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: transparent;
  color: #1f3893;
  border: 2px solid #1f3893;
  display: inline-block;
  padding: 10px 25px;
  font-weight: 700;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.colorlib-notfound button:hover {
  color: #ffab00;
  border-color: #ffab00;
}

.ats-filds-container .shopify-fields {
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f1f1f1;
}
.shopify-fields-holder {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .colorlib-notfound .colorlib-notfound-404 {
    height: 122px;
    line-height: 122px;
  }

  .colorlib-notfound .colorlib-notfound-404 h1 {
    font-size: 122px;
  }
}

@media only screen and (max-height: 600px) {
  #colorlib-notfound {
    height: 100vh;
  }
}
/* install page*/

.install-page {
  height: auto;
  background: #dbe7f9;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 20px;
  min-height: 100vh;
}
.install-page-inner {
  height: auto;
  text-align: left;
  width: 100%;
  max-width: calc(100% - 240px);
  margin-left: auto;
}
.install-page-text {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
span.ip-f-highlight-text {
  font-size: 25px;
  color: rgba(53, 75, 157);
  font-family: cursive;
}
.ip-image-text {
  display: flex;
  width: auto;
  flex-wrap: wrap;
}
.ip-text {
  color: #000;
  margin-top: 0;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.5px;
}
.image-in-ip {
  display: block;
  justify-content: center;
  width: 100%;
  max-height: 100%;
  max-width: 200px;
  margin: 0;
  /* background: #fff; */
  /* padding: 20px 30px; */
  /* border-radius: 30px; */
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); */
  /* border: 4px solid #d3e1f5; */
}
.image-in-ip-1 {
  background: #fff;
  padding: 20px 20px;
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 4px solid #d3e1f5;
  margin-top: 3px;
}
span.cou-flag {
  margin-left: 5px;
}
.install-page-text .ip-f {
  margin-bottom: 0;
  margin-top: 20px;
}
.install-page-header {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  position: relative;
  right: 20px;
  margin-bottom: 20px;
}
span.ip-video-container {
  max-width: 100%;
  display: block;
  margin: 0;
  width: 100%;
  /* border: 4px inset #fff;
  box-shadow: 0 19px 16px -10px rgba(0,0,0,.5); */
  min-height: 420px;
}
.ip-video-section {
  padding: 0px 0;
}
.ip-video-txt {
  text-align: center;
  color: #aba9a9;
  font-size: 32px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}
p.ip-video-pre {
  font-size: 16px;
  margin-bottom: 35px;
  color: #999;
  margin-top: 15px;
  font-weight: 700;
  color: RGB(120, 172, 178);
}
.v-slider-container {
  position: relative;
  max-width: 250px;
  text-align: left;
  margin-top: 30px;
}
.v-slide-item {
  max-width: 200px;
  background: #1f3893;
  position: relative;
  overflow: hidden;
}
.v-slide-item h3 {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
  text-align: center;
}
.i-left-part {
  max-width: 200px;
  width: 100%;
  position: fixed;
}
span.ip-btn-group {
  margin-left: auto;
  opacity: 0.8;
}
span.v-slider-txt {
  background: transparent;
  display: block;
  padding: 10px;
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #1f3893;
  border-radius: 30px 30px 0 0;
  border-bottom: 2px solid #fff;
}
/* .video-react.video-react-fluid {
  border: 4px inset #fff;
  box-shadow: 0 19px 16px -10px rgba(0,0,0,.5);
} */
button.video-react-big-play-button.video-react-big-play-button-left {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #f6ab36;
}
.video-react:hover .video-react-big-play-button,
.video-react .video-react-big-play-button:focus {
  background-color: #1f3893;
}
.pro-txt-holder {
  position: absolute;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding: 10px 15px;
  background: rgba(219, 231, 249, 0);
  border-radius: 5px;
  margin: 0;
  border: 1px solid #fff;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.pro-txt-holder span {
  color: #444;
  height: 100%;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 0;
}
.pro-img-holder {
  opacity: 0.8;
  border: 3px solid #fff;
  height: 150px;
  display: flex;
}
/* .slick-slider.slick-vertical.slick-initialized::after {
  position: absolute;
  content: " ";
  height: 140px;
  width: 100%;
  background: -moz-linear-gradient(
    180deg,
    rgba(219, 231, 249, 1) 0%,
    rgba(36, 36, 36, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(219, 231, 249, 1) 0%,
    rgba(36, 36, 36, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(219, 231, 249, 1) 0%,
    rgba(36, 36, 36, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbe7f9",endColorstr="#242424",GradientType=1);
  top: 0;
  opacity: 0.9;
  left: 0;
} */
.slick-slider.slick-vertical.slick-initialized::before {
  position: absolute;
  content: " ";
  height: 140px;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(219, 231, 249, 1) 0%,
    rgba(36, 36, 36, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbe7f9",endColorstr="#242424",GradientType=1);
  bottom: 0;
  opacity: 1;
  z-index: 99;
  -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbe7f9",endColorstr="#242424",GradientType=1);
  left: 0;
}
.slick-slider.slick-vertical.slick-initialized {
  /* border-bottom: 2px solid #fff; */
  /* pointer-events: none; */
}
.v-slider-txt p {
  color: #1f3893;
  font-size: 18px;
  letter-spacing: 0.5px;
}
.ip-twopart-section {
  display: flex;
  justify-content: space-between;
}
.ip-platform-section {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  padding: 50px 20px;
  justify-content: center;
  text-align: center;
}
.ip-platform-section h2.ip-video-txt {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin-bottom: 40px;
}
.platform-item {
  margin: 0 50px;
}
.ip-video-section {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
}
.platform-holder {
  display: flex;
  opacity: 0.7;
}
.ip-mpl-section {
  text-align: center;
}
.mpl-holder {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  opacity: 0.7;
}
.mpl-item {
  max-width: 250px;
  width: 100%;
}
span.pl-img-holder {
  display: block;
  width: 100px;
  margin: 0 auto;
}
span.cs-txt-holder {
  text-align: center;
}
span.cs-qoute {
  display: flex;
  justify-content: center;
  font-size: 58px;
  height: 40px;
  align-items: center;
}
.cs-slider-holder {
  pointer-events: none;
}
.cs-rating-group {
  display: flex;
  justify-content: center;
}
.cs-rating-group span {
  font-size: 32px !important;
  color: #f6ab36 !important;
}
.cs-txt-holder p {
  font-size: 16px;
  letter-spacing: 0.5px;
  width: 50%;
  margin: 0 auto 20px;
}
.y-video-container::after {
  text-align: center;
}
table.pro-data-table.in-data-table th,
table.pro-data-table.in-data-table td.table-action-buttons,
table.pro-data-table.in-data-table .pro-small-txt .block_discription {
  text-align: center;
}
table.pro-data-table.in-data-table .t-pro-links .t-link-icon {
  min-height: 42px;
}
table.pro-data-table.in-data-table {
  margin-top: 20px;
}
span.ip-head-txt {
  margin-top: 30px;
}
.iinstall-page-inner {
  width: 100%;
}
.iinstall-page-inner .install-page-header {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  position: relative;
  right: 0;
  margin-bottom: 20px;
  align-items: center;
}
.iinstall-page-inner span.ip-btn-group {
  margin-left: auto;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
}
.iinstall-page-inner span.ip-head-txt {
  margin: 30px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.ip-video-txt {
  width: 100%;
  font-weight: 700;
  color: RGB(95, 158, 160);
  opacity: 0.8;
}
table.pro-data-table.in-data-table tbody {
  height: 300px;
  overflow: hidden;
}
table.pro-data-table.in-data-table tbody tr {
  top: 100%;
  left: 0;
  right: 0;
  animation: scrollUp 10s ease-in-out infinite normal;
  -webkit-animation: scrollUp 10s ease-in-out infinite normal;
}
/* home single page */
.main-container-home {
  position: relative;
  width: 100%;
  max-width: 100%;
  display: block;
  /* transition: width 0.05s; */
  background: #ffff;
  min-height: 250px;
}
.ms-logo {
  position: absolute;
  margin: 18px 0px;
  left: 15px;
}
/* engage */

.p-engage-field .p-engage-data {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 8px auto;
  width: 100%;
  position: relative;
  align-items: center;
}

/* spinner */
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/* account*/
.background {
  padding: 0 25px 15px;
  position: relative;
  width: 100%;
  font-family: "Open Sans", sans-serif;
}

.background::after {
  content: "";
  height: 275px;
  background: linear-gradient(90deg, #e0eafc, #cfdef3);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media (min-width: 900px) {
  .background {
    padding: 0 0 15 px;
  }
}

.acc-info .acc-info-container {
  margin: 0 auto;
  padding: 0;
  max-width: 65rem;
  width: 100%;
  display: flex;
}
.acc-info-container {
  margin: auto;
  padding-top: 15px;
  max-width: 65rem;
  width: 100%;
}
.panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 25px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.pricing-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08),
    0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
}

.pricing-table * {
  text-align: center;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 25px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

.pricing-img {
  margin-bottom: 10px;
  max-width: 100%;
  height: 110px;
}

.pricing-header {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
}

.pricing-features {
  color: #558cee;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0;
}
.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 13px;
  line-height: 1.5;
  padding: 5px 0;
}
.loader {
  height: 60vh;
}
.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}
.logout-sidebar.sidenav---sidenav-navitem---uwIJ- {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
}
button.close-bp.btn.btn-danger {
  min-width: fit-content;
  padding: 4px 17px !important;
}
.nav-logo-sidebar {
  margin: 25% auto;
  background: #fff;
  align-items: center;
  padding: 8px !important;
  border-radius: 10px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 2px solid #d3e1f5;
}
.pricing-price {
  color: #558cee;
  display: block;
  font-size: 32px;
  font-weight: 700;
}
button.btn-home.btn.btn-primary {
  min-width: 85px !important;
}
.pricing-button {
  border: 1px solid #558cee;
  border-radius: 10px;
  color: #348efe;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 35px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}
span.pricing-price-subtext {
  font-size: 15px;
  font-weight: 500;
}
.pricing-button:hover,
.pricing-button:focus {
  background-color: #e1f1ff;
}

.pricing-button.is-featured {
  background-color: #276de9;
  color: #fff;
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
  opacity: 0.9;
}
.acc-info {
  display: flex;
}
ul.ul-paln-info,
.ul-acc-info {
  width: 100%;
  color: #558cee;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.background::after {
  content: "";
  height: 275px;
  background: linear-gradient(90deg, #e0eafc, #cfdef3);
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 30px);
  z-index: 1;
  right: 0;
  margin: 0 auto;
}
.account-details-list {
  background: #fff;
  border-radius: 10px;
  position: relative;
  margin-right: 30px;
  z-index: 2;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.08),
    0 20px 31px 3px rgba(0, 0, 0, 0.09), 0 8px 20px 7px rgba(0, 0, 0, 0.02);
}
ul.ul-paln-info.account-details-list:last-child {
  margin-right: 0 !important;
}
.account-details-list li {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  color: #222;
}
.account-details-list li:not(:last-child) {
  border-bottom: 1px solid #f5f5f5;
}
.account-details-list li span.li-info-field {
  color: #558cee;
}
@media (min-width: 900px) {
  .pricing-plan {
    border-bottom: none;
    border-right: 1px solid #e1f1ff;
    flex-basis: 100%;
    padding: 10px 50px;
  }
  .pricing-table {
    flex-direction: row;
  }
  .pricing-plan:last-child {
    border-right: none;
  }
}

.s-pro-block-content .engage-field,
.other-info {
  display: flex;
}
.s-pro-block-content .engage-field .engage-list-item,
.other-info .other-info-list-item {
  display: flex;
  flex-direction: column;
  width: 200px;
  border: 1px solid #f1f1f1;
  padding: 10px;
}
.s-pro-block-content .engage-field .engege-txt,
.other-info .other-info-txt {
  font-size: 16px;
  color: #000;
  margin-bottom: 5px;
}
.s-pro-block-content .engage-field .engage-data,
.other-info .other-info-data {
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-pro-block-content .engage-field .engage-data span,
.other-info .other-info-data span {
  margin-left: 10px;
  color: #222;
}

.shopify-modal-container {
  padding: 1rem;
}
.pro-small-txt .block_discription {
  display: flex;
  align-items: center;
}
/* influencer css */
.social-acc,
.social-acc-followers {
  display: flex;
}
ul.influ-list-group {
  display: flex;
  max-width: 100%;
  justify-content: unset;
  margin: 50px 0 30px;
  flex-wrap: wrap;
  text-align: center;
}
.il-img-holder {
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px;
}
li.influ-list-item {
  max-width: 100%;
  width: 42%;
  padding: 20px 20px;
  background: #fff;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin: 0 20px 20px;
}
li.influ-list-item:nth-child(odd) {
  margin-left: 0;
}
p.il-details {
  margin-bottom: 0;
}
h3.il-title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.influ-info span {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
p.il-details {
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.influ-info span {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
span.header-field-text {
  color: #000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  margin-right: 20px;
  text-decoration: none !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px;
}
.ragister-influencer .form-group {
  display: flex;
  flex-wrap: wrap;
}
.m-social-group-item label.form-label {
  background: #f1f1f1;
  margin: 0;
  padding: 5px 10px;
  border-radius: 5px 0 0 5px;
}
.m-social-group-item input {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  font-size: 14px;
  letter-spacing: 0.5px;
  box-shadow: none !important;
}
.social-acc,
.social-acc-followers {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.m-social-group-item {
  width: 23%;
}
.about-influ {
  display: flex;
  width: 100%;
  max-width: 90%;
  flex-wrap: wrap;
  margin: 0 auto;
  /* align-items: center; */
}
.ab-left {
  width: 40%;
  text-align: left;
}
.ab-right {
  width: 60%;
}

ul.ul-acc-info.account-details-list li {
  width: 100% !important;
}
.section-head {
  display: flex;
  max-width: 90%;
  margin: 0 auto 50px;
  justify-content: space-between;
  align-items: center;
}
.section-head .section-title {
  margin: 0;
}
p.influ-pre {
  font-size: 20px;
}
.influ-txt-container {
  text-align: left;
  max-width: 90%;
  margin: 0 auto;
}
.mm-seo span.mm-img {
  width: 50%;
}
h4.ab-title {
  color: #222;
  letter-spacing: 0.5px;
}
p.ab-pre {
  font-size: 14px;
  letter-spacing: 0.5px;
}
.header-snm {
  position: absolute;
  right: 0;
  top: -6px;
}
.about-sup {
  max-width: 90%;
  margin: 0 auto 50px;
  display: flex;
}
.p-cat-container {
  background: rgba(31, 56, 147, 0.2);
  color: #000;
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.pro-cat {
  display: flex;
  margin: 25px 0;
}
.fbads-n-other {
  display: flex;
  align-items: center;
}
.fbads-n-other .s-pro-block-content {
  width: 63%;
}
.fbads-n-other .other-info {
  display: inline-flex;
  width: 36%;
  margin-left: auto;
}
.supplier-first-portion .form-group,
.supplier-second-portion .form-group {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.supplier-first-portion .input-group .container {
  padding: 0;
}
.ab-right {
  width: 60%;
  padding: 0 90px;
}
.about-sup .ab-right {
  padding: 0;
}
.about-influ .ab-right img {
  width: 100%;
}
span.mm-title {
  font-size: 14px;
  color: #666;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  display: inline-block;
}
span.mm-value {
  font-size: 16px;
  margin-left: 10px;
  color: #000;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}
.country-data {
  max-width: 160px;
}
span.mm-label {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  letter-spacing: 0.5px;
  color: #666;
}
.header-snm span:first-child {
  margin-right: 15px;
  font-size: 14px;
  color: #222;
  letter-spacing: 0.5px;
  font-weight: 500;
}
button.header-field-text-t.btn.btn-primary {
  min-width: 144px;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.nav-tabs .nav-link {
  border-radius: 20px 20px 0 0;
  font-size: 14px;
  font-weight: 500;
  color: #999;
  letter-spacing: 0.5px;
  padding: 12px 20px;
  outline: 0 !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #1f3893 !important;
}
.mm-data {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
}
span.mm-img {
  width: 220px;
  margin: 0 auto;
}
.mm-txt {
  width: 100%;
  margin: 30px 0 0;
  text-align: center;
  font-size: 26px;
  color: #333;
  letter-spacing: 0.5px;
}
.mm-txt strong {
  color: #1e3aa1;
}
.bp-data {
  padding: 25px 0 0;
}
.bp-img {
  width: 100%;
  margin: 0 auto;
  max-width: 275px;
}
.bp-txt h5 {
  font-size: 22px;
  margin-top: 40px;
  color: #1f3893;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.header-in-all {
  padding: 0 20px;
  /* margin-bottom: 20px; */
}
.bp-txt h3 {
  margin-top: 0;
}
.bespoked-product .container {
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  padding: 0 !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
.bp-price {
  display: grid;
}
span.bp-price-value {
  font-weight: 600;
  margin-left: 2px;
}
.req-products .section-title {
  font-size: 30px;
  position: relative;
  padding-bottom: 20px;
  text-align: left;
  max-width: 90%;
  margin: 30px;
}
.bespoked-product .form-group {
  display: flex;
  flex-wrap: wrap;
}
.select-service {
  display: none;
}
span.job-count {
  background: #dc3545;
  padding: 2px 8px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  margin-left: 4px;
  line-height: 17px;
  vertical-align: middle;
}
p.plan-msg-value {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding: 5px 20px;
  font-weight: 700;
  color: #bb5504;
  background: #ffde92;
}
.plan-redirect {
  color: #1f3893;
  cursor: pointer;
}
li.user-icon-list-item {
  position: absolute;
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
span.u-icon-holder {
  width: 65px;
  height: 65px;
  overflow: hidden;
  display: flex;
  border-radius: 100%;
  border: 3px solid rgba(255, 255, 255, 0.5);
}
.cs-slider-holder {
  position: relative;
  width: 80%;
  margin: 0 auto;
}
li.user-icon-list-item.u1 {
  left: 12%;
  top: -30px;
  animation-delay: 2s;
}
li.user-icon-list-item.u2 {
  top: 40%;
  transform: translateY(-60%);
  left: 5%;
  animation-delay: 1s;
  -webkit-transform: translateY(-60%);
  -moz-transform: translateY(-60%);
  -ms-transform: translateY(-60%);
  -o-transform: translateY(-60%);
}
li.user-icon-list-item.u3 {
  bottom: 0;
  left: 15%;
  animation-delay: 3s;
}
li.user-icon-list-item.u4 {
  right: 15%;
  top: -15px;
  animation-delay: 1.5s;
}
li.user-icon-list-item.u5 {
  top: 40%;
  transform: translateY(-60%);
  right: 5%;
  animation-delay: 2s;
  -webkit-transform: translateY(-60%);
  -moz-transform: translateY(-60%);
  -ms-transform: translateY(-60%);
  -o-transform: translateY(-60%);
}
li.user-icon-list-item.u6 {
  right: 13%;
  bottom: 0;
  animation-delay: 3s;
}
li.user-icon-list-item.u8 {
  right: 20%;
  top: 40%;
  animation-delay: 2.5s;
}

li.user-icon-list-item.u3 span.u-icon-holder,
li.user-icon-list-item.u4 span.u-icon-holder {
  width: 70px;
  height: 70px;
}
li.user-icon-list-item.u8 span.u-icon-holder {
  width: 45px;
  height: 45px;
}
li.user-icon-list-item.u7 span.u-icon-holder {
  width: 55px;
  height: 55px;
}
.cs-slider-holder .slick-slider.slick-initialized {
  width: 100%;
  margin: 0 auto;
  max-width: 900px;
}
@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
}
.container-home tbody {
  list-style: none;
  position: relative;
  /* display: table-row-group; */
}
table.pro-data-table.in-data-table.home-table {
  background: #fff;
  width: 90%;
  margin: 0 auto 50px;
}
.iinstall-page-inner .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  height: auto;
  position: relative;
  margin-bottom: 50px;
}
.iinstall-page-inner .table-responsive::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 200px;
  bottom: 0;
  background: linear-gradient(0deg, #dbe7f9, rgba(36, 36, 36, 0));
  left: 0;
}

table.pro-data-table.in-data-table.home-table .slick-slider {
  touch-action: auto;
}

.table-row {
  display: flex !important;
  width: 100%;
  border-bottom: 1px solid #dbe7f9;
  align-items: stretch;
  outline: none;
}

.table-heading-cell {
  display: inline-block;
}
.table-cell {
  display: flex;
  position: relative;
  padding: 15px 10px;
  align-items: center;
  justify-content: center;
}
.table-heading-cell {
  display: inline-block;
  font-size: 12px;
  padding: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #555;
}
.table-row.table-thead {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
}
.table-heading-cell:not(:last-child) {
  border-right: 1px solid #dbe7f9;
}
.table-cell .t-product-title {
  font-size: 12px;
  line-height: 15px;
  height: 31px;
  letter-spacing: 0.5px;
  overflow: hidden;
  margin: 10px 0 5px;
}
.table-cell .profit-cost-inner div {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin: 10px auto;
}
.table-cell:not(:last-child) {
  border-right: 1px solid #dbe7f9;
}
.table-cell .profit-cost-inner {
  width: 100%;
}
.table-cell .t-product-analytics div {
  font-size: 12px;
  padding: 5px 0;
}
.table-cell .pro-small-txt {
  font-size: 14px;
  min-width: 100%;
  word-break: break-word;
  max-height: 100%;
}
.table-cell .y-video-container:after {
  bottom: 8px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 700;
  white-space: nowrap;
  left: -10px;
  bottom: 0;
}
.table-row.table-thead {
  background: rgba(211, 225, 245, 0.5);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
}
.table-cell .pro-small-txt .t-product-img-container {
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
}
.slick-slide .table-row {
  display: table-cell;
  vertical-align: middle;
}
table.pro-data-table.in-data-table .table-cell .t-pro-links {
  justify-content: center;
}
table.pro-data-table.in-data-table .slick-vertical .slick-slide {
  border: 0;
}
table.pro-data-table.in-data-table .table-cell .t-pro-links .t-link-icon {
  min-height: 30px;
  min-width: 30px;
}
.table-cell .pro-small-txt .block_discription {
  font-size: 12px;
}
.table-cell .btn.btn-primary {
  background: #1f3893;
  border-color: #1f3893;
  border-radius: 100px;
  padding: 7px 8px !important;
  min-width: 100px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  opacity: 0.8;
}
.table-cell .pro-img-viewmore-btn {
  width: auto;
  letter-spacing: 0.5px;
  background: #1f3893;
  color: #fff;
  text-decoration: none;
  border-radius: 100px;
  padding: 3px 10px;
  margin: 5px auto 0;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
  max-width: 92px;
  opacity: 0.8;
}
.table-cell .p-engage-data span {
  vertical-align: middle;
  line-height: 25px;
  font-size: 12px;
}
.table-cell .t-product-img-container {
  width: 80px;
  height: 80px;
}

p.in-alert-msg {
  font-size: 14px;
  display: block;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
  color: #84142d;
  font-weight: 500;
}
span.show-reg-popup {
  cursor: pointer;
  color: #f00;
}
.is-address {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.is-address .form-control {
  margin-right: 5px;
}
.is-address:last-child {
  margin-right: 0px !important;
}
.m-social-group-item span {
  font-size: 14px;
  letter-spacing: 0.5px;
  word-break: break-all;
}
.social-acc {
  position: relative;
}
.social-acc-value {
  cursor: pointer;
  display: inline-grid;
  min-width: 50px;
  min-height: 50px;
  align-items: center;
  text-align: center;
  text-align: -webkit-center;
}
.tooltip-inner {
  max-width: 100% !important;
}
.home-page-loader {
  min-width: 300px;
  min-height: 300px;
}

.supplier-product-inn {
  max-height: 65px;
  overflow: auto;
}
.social-eg {
  margin-bottom: 0px;
  display: inherit;
}
span.charge {
  width: 100%;
}
.s-acc-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.loader-container {
  min-width: 300px;
  min-height: 300px;
}
span.view-product {
  font-size: 15px;
  cursor: pointer;
  color: #008000;
}
.view-product:hover {
  color: #006400;
}
.product-niche-msg {
  margin: 20px 0px 25px 0px;
}
.color-bp {
  color: blue;
}
.nav-logo {
  margin: auto;
  background: #fff;
  align-items: center;
  padding: 8px !important;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 2px solid #d3e1f5;
}
.faq-btn {
  margin-top: 8px;
}
.spin-sp {
  margin-left: 4px;
}
.p-cat-container.cat-hover {
  cursor: pointer;
}
@keyframes glowing {
  0% {
    box-shadow: 0 0 2px #1f3893;
  }
  40% {
    box-shadow: 0 0 8px #1f3893;
  }
  60% {
    box-shadow: 0 0 8px #1f3893;
  }
  100% {
    box-shadow: 0 0 2px #1f3893;
  }
}

.rainbow {
  animation: glowing 3000ms infinite;
  width: 100%;
}

.cat-pro.input-group {
  display: unset !important;
}

.sub-plan-container {
  padding: 0% 10%;
}
.pdf-sup {
  cursor: pointer;
}
.supplier-second-portion {
  padding-top: 2%;
}
.err-val-msg {
  color: red;
  font-size: 12px;
}
span.change-pwd {
  display: block;
  font-size: 12px;
  text-align: right;
  font-weight: 400;
  color: #888;
  cursor: pointer;
}
.change-pwd-modal .form-group {
  font-size: 14px;
  display: block;
}
span.forgot-pwd {
  font-size: 12px;
  cursor: pointer;
  color: #6495ed;
  text-align: right;
  display: block;
  width: 100%;
  margin-top: 2%;
}
.root-page-forgot-pwd .container-header {
  padding: 12px 15px;
  font-weight: 700;
}
.forgot-pwd-container {
  width: 55%;
  margin: auto;
  padding: 2% 3%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.change-pwd-text {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 15px;
}
.forgot-pwd-container .form-group {
  display: block;
  text-align: left;
  font-size: 14px;
}
.root-page-forgot-pwd .main-container {
  width: 100% !important;
}
.class-expired {
  margin-top: 5%;
}
span.try-again {
  cursor: pointer;
  color: #1f3893;
  opacity: 0.8;
}
.not-allow-btns {
  display: inline-block;
  cursor: not-allowed !important;
}
.plan-btns {
  display: inline-block;
  cursor: pointer;
}
.no-event {
  pointer-events: none;
}
.input-cat-supplier {
  width: 100%;
}
.react-tagsinput-tag {
  background-color: #dddfed;
  border: 1px solid #afb5db;
  color: #1f3893;
  padding: 3px 5px;
}
.react-tagsinput-input {
  width: 110px;
}
.react-tagsinput {
  width: 100%;
  border-radius: 5px;
}
.react-tagsinput--focused {
  border-color: #9ea3be;
}
span.hint {
  font-size: 12px;
  color: gray;
  padding-left: 2px;
}
.in-social {
  display: flex;
}

.btn-links .swal-footer {
  text-align: center !important;
}
img.logo-pwd {
  width: 14%;
  padding: 0% 1% 1% 1%;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 4px solid #d3e1f5;
  margin: 0% 1% 1% 1%;
}
img.img-yt {
  width: 160px;
  cursor: pointer;
  border-radius: 5%;
}
.current-plan {
  font-size: 17px;
  color: #32cd32;
  margin-top: 5px;
  font-weight: 700;
  padding: 5px 0px;
  width: 100%;
}
.next-date {
  margin-bottom: 15px;
  text-align: left;
}
.login-msg {
  display: block;
  text-align: right;
  padding: 0px 15px 15px 0px;
  font-size: 14px;
  color: #808080;
}
.pricing-button-down.is-featured {
  color: #888;
  font-weight: 400;
  margin: 10px 0;
  padding: 10px 35px;
}
.next-date-acc {
  padding: 0% 0% 5px 0%;
  color: #888;
  text-align: left;
  max-width: 60rem;
  margin: auto;
}
.acc-plan {
  margin-bottom: 2%;
}
.card-no-fail {
  padding: 0 0% 1% 0;
  color: #888;
  text-align: left;
  max-width: 60rem;
  margin: auto;
}
.active-sub {
  cursor: pointer;
  color: #276de9;
}
span.ref-code-container {
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 12px;
  justify-content: space-evenly;
}
button.share-btn.btn.btn-primary {
  margin-right: 5px;
}
.f-emails {
  margin-bottom: 5px;
}
.ref-email .form-group {
  display: block;
}
span.email-ref {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
  color: #1f3893;
}
.card-no {
  text-align: left;
}
span.msg-no {
  font-weight: 700;
}
span.green-mark {
  margin-right: 5px;
}
span.red-mark {
  margin-right: 5px;
}
td.prev-plan {
  text-decoration: red line-through;
  width: 20%;
}

.plan-text-dec {
  text-decoration: red line-through;
}
.pandemic-msg {
  color: #bb5504;
  margin-bottom: 2px;
  margin: 0px 12px;
}
.ref-email .form-control {
  border-radius: 20px;
}
.supplier-first-portion label.form-label {
  color: #000;
}
.ragister-influencer label.form-label {
  color: #000;
}
.ref-email label.form-label {
  color: #000;
}
.ref-email .modal-body {
  width: 95%;
  margin: auto;
}

.gen-code-container {
  height: 220px;
  text-align: center;
}

.next:hover {
  background-color: #ddd;
  color: black;
}

.text-info-ref {
  margin: 30px;
  color: gray;
  margin-bottom: 15px;
}

.previous:hover {
  background-color: #ddd;
  color: black;
}
.step-footer.modal-footer {
  justify-content: space-between !important;
}
.sub-close-step button.btn.btn-primary {
  margin-right: 10px;
}
.ref-code-display {
  font-size: 20px;
  font-weight: 700;
  color: gray;
  letter-spacing: 1px;
}
button.next.btn.btn-primary {
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  width: fit-content;
  margin-left: auto !important;
  display: block;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  border: none;
}
.previous {
  background-color: #f1f1f1;
  color: black;
  text-decoration: none;
  display: inline-block;
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 100px;
  font-weight: 500;
  letter-spacing: 0.5px;
  border: none;
  cursor: pointer;
}
button.disable-btn.btn.btn-primary {
  cursor: not-allowed;
  display: none;
}

.container-ref {
  margin-top: 10px;
  color: gray;
}
.mar-code {
  margin-left: 5px;
}
span.ref-code-display-acc {
  color: #558cee;
  font-size: 15px;
}
.container-ref-acc {
  font-size: 12px;
  color: #000;
}
.ref-code-gen {
  display: inline-block;
  margin-right: 5px;
}
.container-ref-acc {
  display: table;
}
.sub-text-ref {
  color: #000;
}

.single-btn {
  color: gray;
}
.swal-overlay {
  z-index: 20999 !important;
}






/* @media */

@media (max-width: 1080px) {
  .table-cell {
    min-width: 200px;
  }
  .table-cell,
  .table-heading-cell {
    min-width: 200px;
  }
  .table-heading-cell:first-child,
  .table-heading-cell:first-child {
    min-width: 220px;
  }
}
@media (max-width: 767px) {
  /* .container-ref-acc {
    max-width: 110px;
} */
  .text-info-ref {
    margin: 5px;
    color: gray;
  }
  .ref-email .modal-body {
    width: 98%;
  }
  .pandemic-msg {
    font-size: 14px;
    margin: 0;
    margin-bottom: 2px;
  }
  .next-date-acc {
    max-width: 13rem;
    font-size: 14px;
  }
  .card-no-fail {
    max-width: 13rem;
    font-size: 14px;
  }
  img.logo-pwd {
    width: 35%;
    margin: 0% 1% 5% 1%;
  }
  .forgot-pwd-container {
    width: 85%;
    padding: 3% 5%;
  }
  .pro-cat {
    display: block;
  }
  .p-cat-container {
    margin-right: auto;
    width: 85%;
    margin-bottom: 5px !important;
    margin: auto;
  }
  .country-data {
    max-width: unset;
  }
  .container-home-single-products {
    padding: 0px 0px;
  }
  .main-container-home .product-details-container {
    width: 100%;
  }
  .main-container-home .product-image-list .carousel.carousel-slider {
    min-width: 245px;
  }
  .header-snm {
    position: relative;
    right: 0;
    top: 0;
    margin-bottom: 20px;
  }
  .ms-logo {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    margin-top: 15px;
    margin-bottom: 5px;
    left: unset;
  }
  .breadcrumb-list li {
    margin: auto;
  }

  span.cou-name-vd {
    font-size: 12px;
  }

  .ms-logo .logo-vd {
    height: 140px;
    width: 140px;
    min-width: 140px;
    min-height: 140px;
  }
  .cs-txt-holder p {
    width: auto;
  }
  .iinstall-page-inner span.ip-head-txt {
    order: 3;
    margin-bottom: 0;
  }
  .iinstall-page-inner span.ip-btn-group {
    position: relative;
    order: 2;
    margin-top: 40px;
    margin-right: auto;
  }
  .ip-video-txt {
    font-size: 22px;
  }
  .iinstall-page-inner .install-page-header {
    flex-wrap: wrap;
  }
  .nav-tabs .nav-link {
    font-size: 14px;
    font-weight: 500;
    color: #999;
    letter-spacing: 0.5px;
    padding: 12px 20px;
    width: 100%;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-radius: 0;
    border-top: 1px solid #dee2e6;
  }
  button.header-field-text-t.btn.btn-primary {
    margin-bottom: 10px;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #dee2e6;
    border-radius: 0;
  }
  .form-main-container {
    padding: 20px;
    max-width: 80%;
  }
  .product-image-list .carousel.carousel-slider {
    min-width: 100%;
  }
  .date-container {
    margin-left: 0;
    font-size: 12px !important;
  }
  li.breadcrumb-item {
    display: none;
  }
  /* mobile view rating */
  span.rating-container {
    /* display: none; */
  }
  span.rate-text {
    color: #1f3893;
    font-size: 14px;
  }
  span.ratings-in-header {
    margin-right: 0;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  .stars-ltr span {
    font-size: 33px;
    position: relative;
    width: 10px;
    cursor: pointer;
    height: 10px;
  }
  span.rate-text {
    text-align: center;
    width: 100%;
  }
  span.ratings-in-header {
    flex-wrap: wrap;
  }
  .s-pro-block-content .engage-field {
    flex-wrap: wrap;
  }
  .sidenav---expanded---1KdUL + .main-container {
    width: 100%;
    padding: 0 15px;
  }

  .sidenav---sidenav---_2tBP {
    z-index: 2099;
    height: 100%;
  }
  .breadcrumb-list .breadcrumb-item,
  .breadcrumb-list a {
    font-size: 12px;
  }
  .grid.product-details {
    margin-top: 1rem;
  }
  .s-pro-title {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  .product-images-container .product-image {
    max-height: 10rem;
  }
  .header-in-all,
  span.shopname-nav {
    font-size: 16px;
  }
  ul.innerClass {
    width: 100%;
    flex-wrap: wrap;
  }
  .search-fields {
    flex-wrap: wrap;
  }

  .search-fields .input-group {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .search-fields .input-group:last-child {
    margin-bottom: 0px;
  }
  ul.innerClass li {
    margin: 0 auto;
  }
  .alert-dismissible.show {
    top: 0;
    z-index: 10999;
  }
  .alert-dismissible.show .alert-heading.h4 {
    font-size: 16px;
    margin-top: 10px;
  }
  .alert-dismissible.show p {
    font-size: 14px;
  }
  .acc-info .acc-info-container {
    width: 100%;
    flex-wrap: wrap;
    padding: 0px;
  }
  .account-details-list {
    margin-right: 0;
  }
  .pricing-plan {
    padding: 0px;
  }
  .alert-dismissible.show .close {
    padding: 2px 10px !important;
  }
  .section-title {
    font-size: 26px;
    margin-bottom: 30px;
  }
  .app-support-btn-group button {
    margin-bottom: 15px;
  }
  .app-support-btn-group {
    flex-wrap: wrap;
  }
  .app-support-form-inner {
    padding: 30px 20px;
  }
  li.itemClass a.linkClass {
    padding: 0px 8px;
  }
  .search-fields .input-group .container {
    padding: 0 0;
    max-width: 100%;
    width: 100%;
  }
  .products-info-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .products-related-boxes {
    flex-wrap: wrap;
  }
  .products-video-row .youtube-videos iframe {
    width: 100%;
    height: 180px;
  }
  .s-pro-block-content .image-list div {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .s-pro-block-content .image-list-icon div {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .product-images-container .product-image {
    height: auto;
  }
  .link-card .t-link-icon,
  .t-pro-links .t-link-icon {
    min-width: 50px;
  }
  .modal-body .shopify-modal-container .grid.product-details {
    flex: wrap;
  }
  .product-youtube-video-modal iframe {
    height: 160px;
    width: 290px;
  }
  .product-details-container h2.pro-title {
    font-size: 16px;
    margin-top: 10px;
  }
  .install-page {
    min-height: 100%;
    flex-wrap: wrap;
  }
  .i-left-part {
    max-width: 100%;
    position: relative;
  }
  .install-page-inner {
    max-width: 100%;
  }
  .install-page-header {
    display: none;
  }
  .ip-video-section {
    width: 100%;
    margin: 30px auto;
  }
  .v-slider-container {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .image-in-ip {
    margin: 0 auto;
  }
  .platform-holder {
    flex-wrap: wrap;
  }
  .platform-item:last-child {
    margin: 0 auto 0px;
  }
  .platform-item {
    margin: 0 auto 20px;
  }
  .mpl-holder {
    flex-wrap: wrap;
  }
  .install-page {
    overflow-x: hidden;
  }
  span.u-icon-holder {
    width: 35px;
    height: 35px;
  }
  .cs-slider-holder {
    width: 100%;
  }
  li.user-icon-list-item.u3 span.u-icon-holder,
  li.user-icon-list-item.u4 span.u-icon-holder {
    width: 42px;
    height: 42px;
  }
  li.user-icon-list-item.u5 {
    bottom: 25%;
  }
  li.user-icon-list-item.u8 {
    right: 38%;
    top: 100%;
  }
  li.user-icon-list-item.u6 {
    right: 15%;
    bottom: 7%;
  }
  li.user-icon-list-item.u2 {
    top: 60%;
    left: -6%;
  }
  li.user-icon-list-item.u5 {
    bottom: auto;
    top: 10%;
  }
  .fbads-n-other .other-info {
    display: flex;
    width: 100%;
    margin-top: 12px;
  }
  .other-info .other-info-list-item {
    padding: 8px 3px;
  }
  .fbads-n-other {
    display: block;
    align-items: center;
  }
  .s-pro-block-content .engage-field .engege-txt,
  .other-info .other-info-txt {
    font-size: 13px;
  }
  .main-container-home .container {
    width: 85%;
  }
}

@media (max-width: 1199px) {
  .acc-info-container {
    max-width: 45rem;
  }
  .acc-info .acc-info-container {
    max-width: 45rem;
  }
  .grid.product-details {
    flex-wrap: wrap;
  }
  .product-images-container {
    width: 100%;
  }
  .product-details-container h2.pro-title {
    margin-top: 10px;
  }
  .product-details-container {
    width: 100%;
  }
  .youtube-videos {
    flex-wrap: wrap;
  }
  .youtube-videos div.s-video-container {
    margin-bottom: 20px;
  }

  .image-list .image-item,
  .product-gallery .carousel.carousel-slider {
    max-height: 180px;
  }
  .s-pro-block-content .image-list {
    flex-wrap: wrap;
    display: flex;
  }
  .s-pro-block-content .image-list-icon {
    flex-wrap: wrap;
    display: flex;
  }
  .product-youtube-video-modal iframe {
    height: 265px;
    width: 465px;
  }
  .t-pro-links {
    width: 160px;
  }
  .y-video-container {
    width: 140px;
  }
  .t-pro-info-container {
    width: 200px;
  }
  .profit-cost-inner {
    width: 140px;
  }
  .t-product-analytics {
    width: 140px;
  }
  .add-to-shopify-content-modal
    .modal-body
    .shopify-modal-container
    .grid.product-details {
    flex-wrap: wrap;
  }
  button.btn.btn-primary.add-to-shopify-btn {
    padding: 5px 4px;
    font-size: 13px;
  }
  .product-youtube-video-modal iframe {
    height: auto !important;
    width: 100% !important;
  }
  .extra-pro-images .modal-body {
    flex-wrap: wrap;
  }
  .extra-pro-images .modal-body .e-pro-main-img {
    width: 100%;
    display: flex;
    border: 2px solid #d1d1d1;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
  }
  .extra-pro-images .modal-body .images-modal div:nth-child(2n) {
    margin-right: 0;
  }
  .extra-pro-images .modal-body .images-modal {
    justify-content: space-between;
    width: 100%;
  }
  .app-support-btn-group .btn {
    margin-left: auto;
    margin-right: auto;
  }
  .accordion {
    width: 90%;
    padding: 20px 15px;
  }
}
@media (max-width: 1175px) and (min-width: 768px) {
  .container-home-single-products {
    padding: 200px 0px;
  }
  .next-date-acc {
    max-width: 40rem;
    font-size: 14px;
  }
  .card-no-fail {
    max-width: 40rem;
    font-size: 14px;
  }
}
@media (max-width: 1599px) and (min-width: 1200px) {
  .link-card .t-link-icon,
  .t-pro-links .t-link-icon {
    width: 31%;
    margin-right: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    position: relative;
    min-width: 40px;
    min-height: 40px;
  }
  .acc-info-container {
    max-width: 60rem;
  }

  .acc-info .acc-info-container {
    max-width: 60rem;
  }
  .t-pro-links .t-link-icon:nth-child(3n) {
    margin-right: 5px;
  }
  .t-pro-links .t-link-icon:nth-child(2n) {
    margin-right: 0;
  }
  .t-pro-links {
    justify-content: space-between;
  }
}

/* custom */
.signup-btn .form-group {
  display: block;
}
.signup-btn .btn-secondary {
  background: #dc3545;
  border-color: #dc3545;
}
/* .winning-dashboard .scrollable{
  height: 100vh;
} */
.winning-dashboard .header-in-all {
  background-color: #fff;
}
.winning-dashboard .scrollable::-webkit-scrollbar-track {
  width: 10px;
  border-radius: 10px;
}
.winning-dashboard .scrollable::-webkit-scrollbar-thumb {
  background: #007aff;
}
.scrollable::-webkit-scrollbar {
  width: 10px;
}

.compose-input {
  outline: none;
}
body .header-in-all {
  background-color: #fff;
}
body .header-in-all .container-header {
  margin: 0 0 0px;
}
.conversation-search input.conversation-search-input::placeholder {
  text-align: left;
}
body .conversation-search-input {
  outline: none;
  padding: 8px 10px 8px 18px;
}

.nav-products {
  margin-top: 25px;
}
span.due-date {
  font-weight: 700;
}
.btn-links .swal-button {
  background-color: #e85348;
  font-size: 20px;
  border: none;
}
.btn-links .swal-button:hover {
  background-color: #e85348;
  font-size: 20px;
  border: none;
}
.btn-links .swal-button:focus {
  box-shadow: none;
}
span.trial-days {
  color: #558cee;
  display: block;
  font-size: 32px;
  font-weight: 700;
}
.trial-days-price {
  font-size: 15px;
  letter-spacing: 0.4px;
  color: #888;
}

.overall-unread-message-count{
  background-color: #1f3893;
  border-radius: 5px;
  color: white;
  font-size: 14px;
    padding: 1px 3px;
    margin-left: 5px;
}
.container--video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video-- {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #f6ab36;
  padding: 5px;
  border-radius: 5px;
}