// @import url("https://fonts.googleapis.com/css?family=Pontano+Sans");

$white: #fff;
$black: #333;
$lightergray: #d5d5d5;
$lightgray: #f0f3f1;
$gray: #e3dddd;
$text: #888;
$button: #3e3e3f;
$button-hover: #565657;
$md: 767px;
* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

// // plan modal
// $transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

// /* Card */
// $card-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
// $card-shadow-hover: 0 14px 28px rgba(0, 0, 0, 0.25),
//   0 10px 10px rgba(0, 0, 0, 0.22);
// .plan-card {
//   transition: $transition;
//   box-shadow: $card-shadow;
//   background: #fff;
//   margin: 20px 10px;
//   cursor: pointer;
//   padding: 8px;
//   width: 49%;
//   border: 2px solid transparent;
//   &:hover {
//     box-shadow: $card-shadow-hover;
//   }
// }
// .plan-modal-body {
//   display: flex;
//   justify-content: space-between;
// }
// /* Selectable */
// $check-size: 20px;
// $selected-color: #44aadd;
// .plan-card .selectable {
//   position: relative;
//   transition: $transition;
//   border: 4px solid transparent;

// }
// .plan-card .check {
//   transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
//   position: absolute;
//   top: 0;
//   right: 0;
//   z-index: 10;
//   width: 20px;
//   height: 20px;
// }
// span.checkmark {
//   float: right;
//   display: block;
//   font: 15px sans-serif;
//   line-height: 12px;
//   padding: 5px;
//   text-align: center;
//   color: #fff;
//   border-color: #44aadd #44aadd rgba(0, 0, 255, 0) rgba(255, 0, 0, 0);
//   background: #44aadd;
// }
// .plan-card.selected {
//   position: relative;
//   border: 2px solid;
//   border-color: $selected-color;
// }
// .ac-plan-name {
//   font-size: 20px;
//   font-weight: 700;
//   color: #44aadd
// }
// .ac-current-plan {
//   text-align: center;
//   font-weight: 600;
// }
// ul.price-features {
//   font-size: 14px;
//   list-style: none;
//   margin-top: 5px;

// }
// button.save-plan-btn.btn.btn-primary {
//   float: right;
// }
// .ac-plan-price {
//   font-weight: 600;
// }
// span.ac-plan-value {
//   font-size: 18px;
// }
// span.ac-plan-period {
//   font-size: 13px;
// }
.youtube-videos {
  display: flex;
  justify-content: space-between;
}
body {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  color: $text;
  background: $white;
  text-rendering: optimizeLegibility;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  color: $text;
  text-decoration: none;
  transition: all 0.2s ease;
  &:hover {
    color: $black;
  }
  &.active {
    color: $black;
  }
}

h1,
h2,
h3,
h4 {
  color: $black;
  font-weight: normal;
  margin: 1.75rem 0 1rem 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.125rem;
  margin: 0;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem 0;
}

section {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

nav {
  display: block;
  li {
    font-size: 1.125rem;
    margin: 0;
  }
}

.flex-nav ul {
  position: absolute;
  z-index: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  display: none;
  width: 100%;
  left: 0;
  padding: 1rem;
  background: $white;
  text-align: center;
  &.active {
    display: flex;
  }
  li {
    margin: 0.5rem 0;
  }
}

.toggle-nav {
  display: flex;
  justify-content: flex-end;
  font-size: 1.125rem;
  line-height: 1.7;
  margin: 1rem 0;
  i {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 0 0 0 0.5rem;
  }
}
.grid.product {
  padding: 0 0 1.5rem 0;
  display: flex;
}
#highlight {
  color: $black;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.price {
  margin: 0;
}

.breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 1rem 0 0 0;
  list-style: none;
  li {
    font-size: 0.85rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }
}

.grid.related-products {
  margin-top: 20px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  border-bottom: 0.0625rem solid #e3dddd;
}

.breadcrumb-item {
  &.active {
    color: $black;
  }
  + .breadcrumb-item {
    &::before {
      content: "/";
      display: inline-block;
      padding: 0 0.5rem;
      color: $lightergray;
    }
  }
}

.description {
  border-top: 0.0625rem solid $gray;
  margin: 2rem 0;
  padding: 1rem 0 0 0;
}

.add-to-cart {
  position: relative;
  display: inline-block;
  background: $button;
  color: $white;
  border: none;
  border-radius: 0;
  padding: 1.25rem 2.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  transform: translateZ(0);
  transition: color 0.3s ease;
  letter-spacing: 0.0625rem;
  &:hover {
    &::before {
      transform: scaleX(1);
    }
  }
  &::before {
    position: absolute;
    content: "";
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $button-hover;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform 0.3s ease-out;
  }
}

.container {
  margin: auto;
  padding: 0 1rem;
  max-width: 75rem;
  width: 100%;
}

.grid {
  > [class*="column-"] {
    padding: 1rem;
  }
  &.menu,
  &.product {
    border-bottom: 0.0625rem solid $gray;
  }
  &.menu {
    > [class*="column-"] {
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
  }
  &.product {
    padding: 0 0 1.5rem 0;
  }
  &.second-nav {
    > [class*="column-"] {
      padding: 0.5rem 1rem;
    }
  }
}

footer {
  padding: 1rem 0;
  text-align: center;
}

.product-image {
  display: none;
}

.image-list {
  li {
    margin: 0;
  }
}
.image-list-icon {
  li {
    margin: 0;
  }
}

@media (min-width: $md) {
  .product-image img,
  .image-list img {
    width: 100%;
  }
  .image-list-icon img {
    width: 100%;
  }

  .product-image {
    display: block;
    img {
      height: 52vh;
      &.active {
        display: block;
        margin: 0 0 0.75rem 0;
      }
    }
  }

  .image-list {
    display: flex;
    overflow: hidden;
    li {
      margin: 0 0.75rem 0 0;
      flex-basis: 100%;
      &:nth-child(3) {
        margin: 0;
      }
    }
    img {
      height: 10rem;
      width: 100%;
      transition: opacity 0.3s ease;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .image-list-icon {
    display: flex;
    overflow: hidden;
    li {
      margin: 0 0.75rem 0 0;
      flex-basis: 100%;
      &:nth-child(3) {
        margin: 0;
      }
    }
    img {
      height: 10rem;
      width: 100%;
      transition: opacity 0.3s ease;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  nav ul {
    justify-content: flex-end;
  }

  .toggle-nav {
    display: none;
  }

  .flex-nav {
    display: block;
    ul {
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: flex-end;
      li {
        font-size: 1.125rem;
        margin: 0 1.5rem 0 0;
        &:nth-child(4) {
          margin: 0;
        }
      }
    }
  }
}

@keyframes fadeImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
// header rating
span.rating-container {
  display: none;
  margin-right: 0;
  margin-left: auto;
}
span a {
  text-decoration: none !important;
}
span.ratings-in-header {
  display: flex;
  align-items: center;
  margin-right: 0;
}
span.rate-text {
  cursor: pointer;
  margin-right: 10px;
}
div#popover-basic {
  z-index: 9999;
  overflow: hidden;
  margin-top: -8px;
}
.pro-data-table.table td {
  position: relative;
}
.cell-date-holder {
  position: absolute;
  left: 0;
  width: 30px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  background: rgba(31, 56, 147, 0.2);
  justify-content: center;
  border-right: 1px solid #f1f1f1;
}
span.date-in-vertical {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  font-size: 14px;
  color: #1f3893;
  white-space: nowrap;
  letter-spacing: 1px;
  font-weight: 700;
}
.product-cell {
  position: unset;
  padding: 0 0px 0 30px;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  align-items: unset;
}
.date-container {
  display: flex;
  margin-left: auto;
  color: #000;
  align-items: center;
  font-size: 14px !important;
  line-height: 14px;
  position: relative;
  background: rgba(246, 171, 54, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 9;
  font-weight: 700;
  letter-spacing: 0.5px !important;
  color: #1f3893;
}
.date-container::after {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 2px;
  top: 0;
  z-index: 99;
  display: block;
  background: #1f3893;
}
span.date-value {
  margin-right: 5px;
}
.date-container span.date-titile {
  margin-right: 10px;
}
.pro-description .rdw-editor-main {
  overflow: hidden;
}
.popover-header {
  font-size: 12px !important;
  padding: 0.4rem 0.75rem !important;
  margin-top: 0 !important;
}
.popover .arrow {
  display: none;
}
span.rate-text {
  color: #1f3893;
  font-size: 20px;
}
.header-snm {
  width: fit-content;
  margin-left: auto;
}
button.header-field-text-t.btn.btn-primary {
  margin-right: 15px;
}
span.header-field-text {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-size: 17px;
  font-weight: 400;
  margin-right: 20px;
  &:hover {
    font-weight: 700;
    text-decoration: underline;
  }
}
span.header-field-text-t {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-size: 15px;
  padding: 0px 4px 0px 0px;
  font-weight: 400;
  &:hover {
    font-weight: 700;
    text-decoration: underline;
  }
}

span.header-fields {
  margin-left: auto;
  align-items: center;
}
.stars-ltr span {
  font-size: 2vw;
  height: 2vw;
  position: relative;
  width: 2vw;
  cursor: pointer;
}
.stars-ltr {
  color: rgb(255, 215, 40);
  display: flex;
  justify-content: center;

  &-full {
    animation: growWidth 7s ease infinite;
    left: 0;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 0%;
  }

  &-outline {
    position: relative;
  }
}

@keyframes growStar {
  0% {
    -webkit-clip-path: polygon(
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 50%
    );
    clip-path: polygon(
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%
    );
  }
  20% {
    -webkit-clip-path: polygon(
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 50%
    );
    clip-path: polygon(
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%,
      50% 52.5%
    );
  }
  50% {
    -webkit-clip-path: polygon(
      50% 3%,
      62% 39%,
      95% 39%,
      69% 59%,
      80% 95%,
      50% 74%,
      20% 95%,
      31% 60%,
      3% 39%,
      39% 39%
    );
    clip-path: polygon(
      50% 3%,
      62% 39%,
      95% 39%,
      69% 59%,
      80% 95%,
      50% 74%,
      20% 95%,
      31% 60%,
      3% 39%,
      39% 39%
    );
  }
  100% {
    -webkit-clip-path: polygon(
      50% 3%,
      62% 39%,
      95% 39%,
      69% 59%,
      80% 95%,
      50% 74%,
      20% 95%,
      31% 60%,
      3% 39%,
      39% 39%
    );
    clip-path: polygon(
      50% 3%,
      62% 39%,
      95% 39%,
      69% 59%,
      80% 95%,
      50% 74%,
      20% 95%,
      31% 60%,
      3% 39%,
      39% 39%
    );
  }
}

@keyframes growWidth {
  0% {
    width: 0%;
  }
  20% {
    width: 80%;
  }
  40% {
    width: 30%;
  }
  60% {
    width: 50%;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

// EDIT SCSS
.mobile-view_{
  display: none;
}
.y-video{
  height:540px;
}
@media screen and (min-width:768px) and (max-width:992px){
  .header-snm {
    position: relative !important;
    margin: 0 auto;
  }
  
}
@media screen and (max-width:575px){
  .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f{display: block !important;text-align: left;}
  .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *{height: .8em !important;}
  .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T{line-height: 58px !important;}
  .mobile-view_{    
    display: flex;
    justify-content: space-around;
    flex-flow: row-reverse;
  }
  .hide-on-desktop{
    &.table-row{
      border-bottom: 10px solid #dbe7f9;
      padding-top: 15px
    }
    button.btn-home.btn.btn-primary,.btn.btn-primary, .signup-btn .btn-secondary{     
      box-shadow: 2px 2px 4px 1px #0b1c5a;
      border: 1px solid #fff;}
    .t-product-img-container{
      box-shadow: none;
      border-radius: 0;
      border:none;
      width: 100% !important;
      height:100% !important;
     }
    tr{
      td:first-child{
        
        .product-cell{    
          display: block;
          text-align: center;
          width: 100%;
          .t-pro-info-container{width: 100%;}
          .cell-date-holder{top:-10px;background-color: #f6ab37!important;}
          .pro-img-viewmore-btn{
            display: none;
          }
          
        }        
      }
      td:not(:first-child){
        display: none;       
              
      }
      th:not(:first-child){
        display: none;
      }
    }
    .table-cell{width:100% !important;
    .product-cell{    display: block;
      text-align: center;
      width: 100%;
      .cell-date-holder{background-color: #f6ab37!important;}
      .t-pro-info-container{width: 100%;}
      .pro-img-viewmore-btn{
        display: none;
      }
    }
    }
    .table-heading-cell{width:100% !important;}
    .table-cell:not(:first-child){
      display: none;
    }
    .table-heading-cell:not(:first-child){
      display: none;
    }
  }
  .header-in-all{
    .container-header{
      display: flex;
      flex-flow: column;
      span.header-fields {
        margin-left: inherit;     
    }
    }
  }
  .sidenav---sidenav---_2tBP{
    left: -64px;
    .sidenav---sidenav-toggle---1KRjR{      
      left: 64px;
      .sidenav---icon-bar---u1f02{
        background:#1f3893;
      }
    }
    &.sidenav---expanded---1KdUL{
      left: 0 !important;
      .sidenav---sidenav-toggle---1KRjR{              
        left: 175px;
        .sidenav---icon-bar---u1f02{
          background: #fff;
        }
      }
    }
    
  }
  .sup-holder{
    .section-head {
      display: initial !important;
      h4{
        margin:0 auto 15px !important;
      }
    }
    .about-sup{
      display: block;
      .ab-left{
        width: 100%;
        text-align: initial;         
      }
      .ab-right{
        width: 100%;
        text-align: initial;
      }
    }
    .about-influ{
      .ab-left{
        width: 100%;
        text-align: initial;
        li.influ-list-item {
          max-width: 100%;
          width: 100%;
          margin: 10px 0;
        }
      }
      .ab-right{
        width: 100%;
        text-align: initial;
      }
    }
  }
  .main-container{
    width: calc(100%) !important;
    
  }
  .product-cell{
    padding:0 !important;
    padding-top:15px;
    .cell-date-holder{
      position: relative;
      top:-15px;
      width: 100%;
      height:30px;
      .date-in-vertical{
        transform: rotate(-0deg) !important;
      }
    }
  }
  .install-page-header{
    .country-data{
      height: 40px;
    display: block;
    }
  }
}
.supplier--list{
  tr td svg{
    margin-left: 10px;
  }
}